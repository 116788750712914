<template>
  <div class="hospital-list">
      <h1>Registered Hospitals</h1>
    <CDataTable
      :striped="striped"
      :bordered="bordered"
      :small="small"
      :items="getItems"
      :fields="fields"
      :items-per-page="small ? limit : 5"
      pagination
    ></CDataTable>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HospitalList",
  props: {
    fields: {
      type: Array,
      default() {
        return ["Name", "Website", "Email", "Address", "Location", "No_of_Beds", "Hospital_Type"];
      }
    },
    limit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
        small: true,
        striped: true,
        bordered: true,
    };
  },
  computed: {
      ...mapGetters(['getHospitalList']),
    getItems() {
      return this.getHospitalList.map(data => {
          const container = {}
            container.Name = data.name
            container.Website = '--'
            container.Email = data.original_customer_id
            container.Address = '--'
            container.Location = '--'
            container.No_of_Beds= '--'
            container.Hospital_Type = data.government ? 'Government' : 'Private'
            return container;
        })
      }
  },
  mounted(){
      this.FetchHospitalList()
  },
  methods: {
    ...mapActions(['FetchHospitalList']),
  }
};
</script>

<style scoped>
.hospital-list {
  margin-bottom: 5rem;
}
</style>